<template>
  <div>
    <div>
      <v-col>
        <v-col v-for="form in formList" :key="form.id">
          <FormCard :formation="form"></FormCard>
        </v-col>
      </v-col>
    </div>
  </div>
</template>

<script>
import formConfig from "../assets/ConfigJSON/formConfig.json";
import FormCard from "../components/FormCard.vue";

export default {
  name: "Formations",
  data() {
    return {
      formList: formConfig,
    };
  },
  methods: {},
  components: { FormCard },
};
</script>
