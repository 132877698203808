<template>
  <div class="about">
    <v-card class="mx-auto elevation-0">
      <v-card-title class="text-h3 text--primary">Bienvenue !</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            pellentesque lacus orci, sit amet posuere felis commodo ac. Nunc
            eget condimentum orci, ut blandit nulla. Phasellus non efficitur
            diam. Praesent diam arcu, mattis semper tellus ac, rhoncus hendrerit
            libero. Ut tincidunt est vel metus faucibus molestie. Nulla ex sem,
            ultrices et ullamcorper vitae, molestie ut nunc. Sed pharetra purus
            eu mollis rutrum. Morbi placerat dictum posuere. Aenean eget magna
            ante. Aliquam vestibulum ligula non risus facilisis, vitae
            vestibulum elit scelerisque. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Nam enim
            sem, feugiat accumsan sapien malesuada, porta iaculis nisl. Etiam
            vel ullamcorper libero. Vestibulum eros nisi, posuere a sagittis
            tempor, tempor vel nulla. Donec sed purus velit. Aliquam a diam
            orci. Nunc faucibus ipsum nec risus sollicitudin, eu dapibus ante
            commodo. Curabitur lorem erat, ultricies eget ipsum eget, bibendum
            sollicitudin enim. Ut id tortor eu diam.
          </v-col>
          <!-- <v-col>
            <v-img
              height="350"
              width="350"
              src="../assets/img_garde.png"
            ></v-img>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="elevation-0 mt-5">
      <v-tabs v-model="tab" center-active class="">
        <v-tab href="#tab-skills"> Compétences </v-tab>
        <v-tab href="#tab-projects"> Projets </v-tab>
        <v-tab href="#tab-experiences"> Expériences </v-tab>
        <v-tab href="#tab-formations"> Formations </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-skills'">
          <Skills></Skills>
        </v-tab-item>
        <v-tab-item :value="'tab-projects'">
          <Projects></Projects>
        </v-tab-item>
        <v-tab-item :value="'tab-experiences'"
          ><Experiences></Experiences
        ></v-tab-item>
        <v-tab-item :value="'tab-formations'"
          ><Formations></Formations>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Experiences from "./Experiences.vue";
import Projects from "./Projects.vue";
import Formations from "./Formations.vue";
import Skills from "./Skills.vue";

export default {
  name: "Home",
  data() {
    return {
      tab: null,
    };
  },
  methods: {},
  components: { Experiences, Projects, Formations, Skills },
};
</script>
