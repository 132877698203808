import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Experiences from "../views/Experiences.vue"
import Formations from "../views/Formations.vue"
import Projects from "../views/Projects.vue"
import Skills from "../views/Skills.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/skills",
  //   name: "Skills",
  //   component: Skills,
  // },
  // {
  //   path: "/experiences",
  //   name: "Experiences",
  //   component: Experiences,
  // },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   component: Projects,
  // },
  // {
  //   path: "/formations",
  //   name: "Formations",
  //   component: Formations,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
