<template>
  <div>
    <v-row no-gutters class="subContent" justify="center">
      <v-col v-for="n in 2" :key="n" cols="12" sm="5">
        <v-card class="pa-2" v-if="n === 1" outlined color="transparent">
          <v-card-text v-for="mission in missions" :key="mission.id">
            <v-icon color="primary lighten-2" class="mr-2">
              mdi-account-group
            </v-icon>
            {{ mission.title }}
          </v-card-text>
        </v-card>
        <v-card class="pa-2" v-if="n === 2" outlined color="transparent">
          <v-card-text v-for="techno in technos" :key="techno.id">
            <v-icon color="primary lighten-2" class="mr-2"> mdi-cog </v-icon
            >{{ techno.title }}
          </v-card-text>
        </v-card>
        <!-- <v-divider class="divider"></v-divider> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ExperiencesContent",
  data() {
    return {};
  },
  methods: {},
  props: ["missions", "technos"],
};
</script>
