<template>
  <v-card shaped>
    <v-card-title class="text-h5 text--primary justify-center">{{
      skill.type
    }}</v-card-title>
    <v-list flat color="transparent">
      <v-list-item v-for="elem in skill.skillsList" :key="elem.id">
        <v-list-item-content class="text-center">
          <v-row>
            <v-col>
              <v-list-item-title
                >{{ elem.title }}
                <v-avatar v-if="elem.icon" class="ml-4" size="30" rounded>
                  <v-img :src="elem.icon"></v-img> </v-avatar
              ></v-list-item-title>
            </v-col>
            <v-col v-if="elem.level">
              <v-list-item-subtitle> {{ elem.level }}</v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "SkillCard",
  data() {
    return {};
  },
  methods: {},
  props: ["skill"],
};
</script>