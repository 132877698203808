<template>
  <v-card height="200" shaped>
    <v-card-title
      ><v-icon class="mr-2" color="primary lighten-2">mdi-school</v-icon
      >{{ formation.title }}</v-card-title
    >
    <v-card-subtitle class="pt-0">{{ formation.school }}</v-card-subtitle>
    <v-card-text class="elip pt-0">{{ formation.desc }} </v-card-text>
    <v-card-text class="pt-0">{{ formation.location }}</v-card-text>
    <v-card-text class="pt-0">{{ formation.dates }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FormCard",
  props: ["formation"],
};
</script>