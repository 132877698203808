<template>
  <div>
    <v-row no-gutters class="subContent">
      <v-col v-for="skill in skillsList" :key="skill.id" cols="14" sm="4">
        <!-- <p>{{ skill.type }}</p> -->
        <SkillCard class="ma-3" :skill="skill"></SkillCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import skillsConfig from "../assets/ConfigJSON/skillsConfig.json";
import SkillCard from "../components/SkillCard.vue";

export default {
  name: "Skills",
  data() {
    return { skillsList: skillsConfig };
  },
  methods: {
    deleteTask(id) {
      this.tasks = this.tasks.filter((task) => task.id !== id);
    },
  },
  components: { SkillCard },
};
</script>
