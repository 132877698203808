<template>
  <v-card shaped>
    <v-card-title
      ><v-icon class="mr-2" color="primary lighten-2">mdi-dumbbell</v-icon
      >{{ project.title }}</v-card-title
    >
    <v-card-subtitle> MàJ: {{ project.lastUpdate }}</v-card-subtitle>
    <v-card-text class="pt-0">{{ project.desc }} </v-card-text>
    <v-card-text class="pt-0">
      <!-- Dialog Details -->
      <div>
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <div class="text-center">
              <v-btn
                class="mb-6"
                color="primary lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Details
              </v-btn>
            </div>
          </template>

          <v-card>
            <v-card-title class="text-h5"
              ><v-icon class="mr-2" color="primary lighten-2"
                >mdi-dumbbell</v-icon
              >
              {{ project.title }}
            </v-card-title>
            <v-card-subtitle> {{ project.date }}</v-card-subtitle>

            <v-card-text class="mt-5" color="primary">
              {{ project.desc }}
              <div class="mt-5">
                <p>{{ "Technos: " + project.techno }}</p>
                <p>{{ "Statut: " + project.status }}</p>
                <p>{{ "Débuté en: " + project.dateCreation }}</p>
              </div></v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red lighten-1" text @click="dialog = false">
                Fermer
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="openProjectPage(project.url)"
                :disabled="!project.isSearchable"
              >
                Consulter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjCard",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openProjectPage(projectUrl) {
      window.open(projectUrl, "_blank");
    },
  },
  props: ["project"],
};
</script>