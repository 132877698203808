<template>
  <div>
    <div class="container">
      <v-row no-gutters class="subContent">
        <v-col v-for="proj in projList" :key="proj.id" cols="14" sm="4">
          <ProjCard class="ma-3" :project="proj"></ProjCard>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import projConfig from "../assets/ConfigJSON/projConfig.json";
import ProjCard from "../components/ProjCard.vue";

export default {
  name: "Projects",
  data() {
    return { projList: projConfig };
  },
  methods: {},
  components: { ProjCard },
};
</script>
