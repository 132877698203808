<template>
  <div>
    <div>
      <v-col>
        <v-col v-for="exp in expList" :key="exp.id">
          <ExpCard :experience="exp"></ExpCard>
        </v-col>
      </v-col>
    </div>
  </div>
</template>

<script>
import expConfig from "../assets/ConfigJSON/expConfig.json";
import ExpCard from "../components/ExpCard.vue";

export default {
  name: "Experiences",
  data() {
    return {
      expList: expConfig,
    };
  },
  methods: {
    fctTest(id) {
      console.log(this.expList.filter((exp) => exp.id === id)[0]);
    },
  },
  components: { ExpCard },
};
</script>
