<template>
  <v-app id="inspire">
    <!-- <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Menu </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar app color="primary" dark src="clouds.jpg" prominent>
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn> -->
      <v-btn color="primary" rounded href="mailto:valcardon39@gmail.com"
        >Me contacter
      </v-btn>
    </v-app-bar>

    <v-main class="">
      <Home></Home>
      <!-- <router-view></router-view> -->
    </v-main>
  </v-app>
</template>

<script>
import Home from "./views/Home.vue";
export default {
  data: () => ({
    // drawer: null,
    // items: [
    //   { title: "Accueil", icon: "mdi-home", to: "/" },
    //   {
    //     title: "Compétences",
    //     icon: "mdi-hammer-screwdriver",
    //     to: "/skills",
    //   },
    //   {
    //     title: "Expériences",
    //     icon: "mdi-briefcase",
    //     to: "/experiences",
    //   },
    //   { title: "Projets", icon: "mdi-dumbbell", to: "/projects" },
    //   {
    //     title: "Formations",
    //     icon: "mdi-school",
    //     to: "/formations",
    //   },
    // ],
  }),
  components: { Home },
};
</script>