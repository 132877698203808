<template>
  <v-card shaped>
    <v-card-title @click:append="fctTest(experience.id)"
      ><v-icon class="mr-2" color="primary ">mdi-briefcase</v-icon
      >{{ experience.post }}</v-card-title
    >
    <v-card-subtitle
      >{{ experience.company }} - {{ experience.contract }} -
      {{ experience.location }}</v-card-subtitle
    >

    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ experience.shortDesc }}
          <template v-slot:actions>
            <v-icon color="primary lighten-2">
              mdi-arrow-down-drop-circle
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ experience.longDesc }}
          <ExpDetailsCard
            :missions="experience.missions"
            :technos="experience.envTechnique"
          ></ExpDetailsCard>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text>{{ experience.dates }}</v-card-text>
  </v-card>
</template>

<script>
import ExpDetailsCard from "./ExpDetailsCard.vue";

export default {
  name: "ExpCard",
  data() {
    return {
      missionsList: this.experience.missions,
    };
  },
  methods: {
    fctTest(id) {
      console.log(this.expList.filter((exp) => exp.id === id)[0]);
    },
  },
  components: { ExpDetailsCard },
  props: ["experience"],
};
</script>